
import React from 'react';

const LoginPage = () => {
    return (
        <div className='container'>
            <footer className="footer">
                <span>info@as5398.com</span>
                <span>Via Girella 20 CH-6814 Lamone</span>
                <span>+41580582319</span>
            </footer>
        </div>
    );
};

export default LoginPage;

