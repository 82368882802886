import React from 'react';
import Logo from "../../assets/as5398.svg";
import mappa from "../../assets/rete-network_as5398.svg";

const Home = () => {
    return (
        <div className="container home_page">
            <img src={Logo} alt="Logo" className='logo' />
            <img src={mappa} alt="Logo" className='mappa' />
        </div>
    );
};

export default Home;