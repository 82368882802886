import { Routes, Route } from 'react-router-dom';
import './App.css';

import Footer from "./components/component/Footer";
import Home from './components/pages/Home';

function App() {
  return (
    <div className='root'>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
